import { render, staticRenderFns } from "./videoDetail.vue?vue&type=template&id=041f3ad8&scoped=true&"
import script from "./videoDetail.vue?vue&type=script&lang=js&"
export * from "./videoDetail.vue?vue&type=script&lang=js&"
import style0 from "./videoDetail.vue?vue&type=style&index=0&id=041f3ad8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "041f3ad8",
  null
  
)

export default component.exports